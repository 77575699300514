import React from "react";
import ReactDOM from "react-dom/client";
import "./input.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Brotherhood from "./Brotherhood";
import Alumni from "./Alumni";
import MenuBar from "./MenuBar";
import { TinyColor } from "@ctrl/tinycolor";
import { Contact } from "./Contact";
import { Rush } from "./Rush";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <MenuBar />
        <App />
      </div>
    ),
  },
  {
    path: "/brotherhood",
    element: (
      <div>
        <MenuBar />
        <Brotherhood />
      </div>
    ),
  },
  {
    path: "/alumni",
    element: (
      <div>
        <MenuBar />
        <Alumni />
      </div>
    ),
  },
  {
    path: "/contact",
    element: (
      <div>
        <MenuBar />
        <Contact />
      </div>
    ),
  },
  {
    path: "/rush",
    element: (
      <div>
        <MenuBar />
        <Rush />
      </div>
    ),
  },
]);

const colors1 = ["#136baa", "#1a2ca4"];

const getHoverColors = (colors) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());
const getActiveColors = (colors) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
