import React from "react";
import { Row, Col } from "antd";

import a16z from "./assets/companies/a16z.png";
import accenture from "./assets/companies/accenture.png";
import apple from "./assets/companies/apple.png";
import bcg from "./assets/companies/bcg.png";
import cisco from "./assets/companies/cisco.png";
import citi from "./assets/companies/citi.png";
import dropbox from "./assets/companies/dropbox.png";
import databricks from "./assets/companies/databricks.png";
import ey from "./assets/companies/ey.png";
import goldmansachs from "./assets/companies/goldmansachs.png";
import google from "./assets/companies/google.png";
import jpmorgan from "./assets/companies/jpmorgan.png";
import linkedin from "./assets/companies/linkedin.png";
import mckinsey from "./assets/companies/mckinsey.png";
import merilllynch from "./assets/companies/merrilllynch.png";
import meta from "./assets/companies/meta.png";
import microsoft from "./assets/companies/microsoft.png";
import moodys from "./assets/companies/moodys.png";
import morganstanley from "./assets/companies/morganstanley.png";
import openai from "./assets/companies/openai.svg.png";
import pwc from "./assets/companies/pwc.png";
import scaleai from "./assets/companies/scaleai.png";
import snapchat from "./assets/companies/snapchat.png";
import insightpartners from "./assets/companies/insightpartners.png";

const Alumni = () => {
  return (
    <section className="w-full lg:pl-40 lg:pr-40 px-4 flex flex-col relative 8 lg:pb-16 pt-20">
      <div className="items-center flex flex-col">
        <h1
          className="text-center font-Raleway font-extrabold mb-8 bg-clip-text"
          style={{ color: "#232323", fontSize: "36px", lineHeight: "1.2" }}
        >
          Alumni
        </h1>
        <p className="description text-center font-Raleway px-4 text-lg" style={{ width: "80%" }}>
          Check out where our alumni are now.
        </p>
      </div>
      <div className="pt-10">
        <Row type="flex" gutter={[16, 16]}>
          {[
            apple,
            accenture,
            a16z,
            bcg,
            cisco,
            citi,
            dropbox,
            insightpartners,
            ey,
            databricks,
            goldmansachs,
            google,
            jpmorgan,
            linkedin,
            mckinsey,
            merilllynch,
            meta,
            microsoft,
            moodys,
            morganstanley,
            openai,
            pwc,
            scaleai,
            snapchat,
          ].map((logo, index) => (
            <Col xs={8} sm={8} md={6} lg={6} xl={6} key={index}>
              <div className="btn-fade w-full flex justify-center rounded-md h-32 md:h-60">
                <img src={logo} className="object-contain px-4" />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default Alumni;
