import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MenuBar = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Header
  useEffect(() => {
    const header = document.getElementById("site-header");

    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      let scrollPos = window.pageYOffset;
      if (scrollPos <= 100) {
        header.style.backgroundColor = "transparent";
      }

      if (currentScroll > lastScrollTop) {
        // Scrolling down
        header.style.transform = "translateY(-100%)";
      } else {
        // Scrolling up
        header.style.transform = "translateY(0)";
        header.style.backgroundColor = "#9dbad4"; // setting background to white when shown
        if (scrollPos <= 100) {
          header.style.backgroundColor = "transparent";
        }
      }

      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { to: "/", text: "Home" },
    { to: "/brotherhood", text: "Brotherhood" },
    { to: "/alumni", text: "Alumni" },
    { to: "/contact", text: "Contact" },
    { to: "/rush", text: "Rush" },
  ];

  return (
    <div
      className="h-20 flex items-center lg:pr-20 md:px-10 px-5 z-50 header w-full fixed"
      id="site-header"
    >
      <div className="pt-10 pb-10">
        <a href="#home">{/* <Logo />{" "} */}</a>
      </div>

      {/* Hamburger menu for mobile */}
      <div className="lg:hidden">
        <button onClick={toggleMenu} className="text-black focus:outline-none">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Desktop menu */}
      <div className="hidden lg:flex items-center pt-10 pb-10">
        {menuItems.map((item, index) => (
          <a
            key={index}
            className="font-Raleway ml-8 font-semibold font-display cursor-pointer link link-underline link-underline-black text-black"
            href={item.to}
          >
            {item.text}
          </a>
        ))}
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="lg:hidden absolute top-20 left-0 right-0 bg-white shadow-md">
          {menuItems.map((item, index) => (
            <a
              key={index}
              className="block py-2 px-4 text-black hover:bg-gray-100"
              href={item.to}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.text}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuBar;
