import React from "react";

export const Contact = () => {
  return (
    <section className="w-full px-8 lg:pl-40 lg:pr-40 flex flex-col relative lg:pb-16 pt-20">
      <h1
        className="text-center font-Raleway font-extrabold mb-8 bg-clip-text"
        style={{ color: "#232323", fontSize: "36px", lineHeight: "1.2" }}
      >
        Contact
      </h1>
      <div className="text-lg">
        Find us on{" "}
        <a className="hover-link" href="https://www.instagram.com/akpsi.stanford/">
          Instagram
        </a>{" "}
        and{" "}
        <a className="hover-link" href="https://www.facebook.com/StanfordAKPsi/">
          Facebook
        </a>
        , or email us at{" "}
        <a className="hover-link" href="mailto:contact@akpsi.org">
          contact@akpsi.org
        </a>
        .
      </div>
    </section>
  );
};
